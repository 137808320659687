// Select the map element and get data attributes
const mapElement = document.getElementById("spf-map");
const latitude = "49.4557";
const longitude = "-2.5371";

// Initialize the Mapbox map
mapboxgl.accessToken =
  "pk.eyJ1IjoiaGFtaWx0b25icm9va2UiLCJhIjoiY20zNG82OXZqMDFxbTJqcXg3dG55ajl6bCJ9.wiiYjZYBOKVWYC7iOum7rQ";
const map = new mapboxgl.Map({
  container: "spf-map",
  style: "mapbox://styles/mapbox/streets-v11",
  center: [longitude, latitude],
  zoom: 15,
});

// Create a custom SVG element
const svgMarker = document.createElement("div");
svgMarker.innerHTML = `
  <svg width="42" height="64" viewBox="0 0 42 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.33,64c1.28-21.3,19.67-26.7,19.67-42.7,0-5.9-2.36-11.2-6.2-15.1-3.74-3.8-8.95-6.2-14.75-6.2S10.03,2.4,6.2,6.2C2.36,10.1,0,15.4,0,21.3c0,16,18.39,21.3,19.67,42.7h2.66Z" fill="#e51a2f"/><polygon points="36.49 16.77 36.49 14.9 28.17 14.9 28.17 27.06 30.3 27.06 30.3 21.81 36.36 21.81 36.36 19.93 30.3 19.93 30.3 16.77 36.49 16.77" fill="#fff"/><path d="M22.43,14.9h0s-5.34,0-5.34,0v12.15h2.13v-4.54h3.21c2.53,0,3.97-1.75,3.97-3.81s-1.42-3.81-3.97-3.81ZM22.14,20.65h-2.91v-3.86h2.91c1.2,0,2.08.77,2.08,1.93s-.87,1.93-2.08,1.93Z" fill="#fff"/><path d="M8.06,18.13c0-.91.8-1.51,2.06-1.51s2.57.44,3.52,1.38l1.22-1.58c-1.13-1.09-2.66-1.69-4.55-1.69-2.66,0-4.43,1.55-4.43,3.55,0,4.48,7.09,2.9,7.09,5.45,0,.8-.69,1.66-2.44,1.66s-3.01-.78-3.84-1.68l-1.18,1.64c1.07,1.15,2.7,1.93,4.94,1.93h-.02c3.28,0,4.7-1.74,4.7-3.76,0-4.54-7.07-3.13-7.07-5.39Z" fill="#fff"/>
  </svg>
`;
svgMarker.style.width = "42px";
svgMarker.style.height = "64px";

// Add the SVG marker to the map with bottom alignment
new mapboxgl.Marker(svgMarker, { anchor: "bottom" })
  .setLngLat([longitude, latitude])
  .addTo(map);
